<template>
<loader v-bind="{ loading }">
  <columns>
    <column>
      <page-heading 
        :heading="user.full_name" 
      />
    </column>
    <column class="is-narrow">
      <tabs>
        <tab-link name="user-manager" :params="{ user: user.uuid }">Overview</tab-link>
        <tab-link name="user-work-orders" :params="{ user: user.uuid }">Work Orders</tab-link>
        <tab-link name="user-checklists" :params="{ user: user.uuid }">Checklists</tab-link>
        <tab-link name="user-attachments" :params="{ user: user.uuid }">Attachments</tab-link>
        <tab-link name="edit-user" :params="{ user: user.uuid }">Edit</tab-link>
      </tabs>
    </column>
  </columns>
  <router-view />
</loader>
</template>
<script>
import { mapGetters } from 'vuex'
export default {

  data: () => ({
    loading: true
  }),

  async beforeCreate() {
    await this.$store.dispatch('user/loadUser', this.$route.params.user)
    this.loading = false
  },

  computed: {
    ...mapGetters('user', [
      'user'
    ])
  }

}
</script>